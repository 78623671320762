import request from '@/utils/request';

export function unMealList(params) {
  return request({
    url: `/icbc/meal/pagination`,
    method: 'get',
    params
  });
}

export function mealedAllList(params) {
  return request({
    url: `/icbc/meal/records/pagination`,
    method: 'get',
    params
  });
}

export function mealOrder(data) {
  return request({
    url: `/icbc/meal/order`,
    method: 'post',
    data
  });
}

export function mealDetail(id, params) {
  return request({
    url: `/icbc/meal/${id}`,
    method: 'get',
    params
  });
}

export function mealCancel(id) {
  return request({
    url: `/icbc/meal/orders/${id}/cancel`,
    method: 'post'
  });
}

// 提交订单API
export function submitAPI(data) {
  return request({
    url: `/icbc/meal/order`,
    method: 'post',
    data
  });
}

// 获取商户系统设置接口
export function getUserSystemSetupAPI(id) {
  return request({
    url: `/organization/setting/${id}`,
    method: 'get'
  });
}

// 数币订餐   /icbc/h5/canOrderDcepPay
export function canOrderDcepPayAPI(data) {
  return request({
    url: `/icbc/h5/canOrderDcepPay`,
    method: 'post',
    data
  });
}

// 删除未支付订餐  icbc/meal/records/cancel/{userId}
export function deleteUnPaymentOrderAPI(userId) {
  return request({
    url: `/icbc/meal/records/cancel?userId=${userId}&phone=`,
    method: 'post'
  });
}

// 获取食堂列表
export function getCanteenListAPI(params) {
  return request({
    url: `/organization/canteen/pagination`,
    method: 'get',
    params
  });
}
// 非白名单订餐详情  /icbc/visit/meal/records/pagination
export function getVisitMealRecordsListAPI(params) {
  return request({
    url: `/icbc/visit/meal/records/pagination`,
    method: 'get',
    params
  });
}
// 非白名单菜品详情  /organization/meal/allProducts/{organizationId}
export function getVisitMealAllProductsListAPI(id) {
  return request({
    url: `/organization/meal/allProducts/${id}`,
    method: 'get'
  });
}
export function getQR(id) {
  return request({
    url: `/icbc/order/${id}/mealCode`,
    method: 'get'
  });
}
export function getAddressListAPI(params) {
  return request({
    url: `/icbc/meal/getAddress?userId=${params.userId || ''}&organizationId=${
      params.organizationId || ''
    }&phone=${params.phone || ''}`,
    method: 'get'
  });
}
// 获取营养推荐配置  /icbc/getNutrition
export function getNutritionConfigAPI(params) {
  return request({
    url: `/icbc/getNutrition?name=${params.name}&organizationId=${params.organizationId}`,
    method: 'get'
  });
}

export function getOrganizationDetailAPI(id) {
  return request({
    url: `/organization/${id}`,
    method: 'get'
  });
}
// /weixin/jsapi/canOrder  微信订餐
export function weixinCanOrderAPI(data) {
  return request({
    url: `/weixin/jsapi/canOrder`,
    method: 'post',
    data
  });
}
// /weixin/jsapi/canOrder  微信订餐
export function weixinCanOrderAPI1(data) {
  return request({
    url: `/weixin/jsapi/canOrder1`,
    method: 'post',
    data
  });
}
// /icbc/meal/addAddress 新增地址
export function addAddressAPI(data) {
  return request({
    url: `/icbc/meal/addAddress`,
    method: 'post',
    data
  });
}
// 删除地址  /icbc/meal/deleteAddress/{id}
export function deleteAddressAPI(id) {
  return request({
    url: `/icbc/meal/deleteAddress/${id}`,
    method: 'post'
  });
}
// 更新地址  /icbc/meal/updateAddress/{id}
export function updateAddressAPI(data) {
  return request({
    url: `/icbc/meal/updateAddress/${data.id}`,
    method: 'post',
    data
  });
}
// 更改默认地址 /icbc/meal/updateAddressDefault/{id}
export function updateAddressDefaultAPI(data) {
  return request({
    url: `/icbc/meal/updateAddressDefault/${data.id}`,
    method: 'post',
    data
  });
}

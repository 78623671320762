<template>
  <div
    class="container"
    style="display: flex; flex-direction: column; width: 100%; height: 100%"
  >
    <topHeader title="订餐" return-path="Order"></topHeader>
    <!-- <div style="width: 100%; background: #fff; display: flex; height: 44px; align-items: center;">
      <div style="height: 30px;margin-left: 16px;display: flex;align-items: center;" @click="toOrder">
        <img style="width: 20px; height: 20px;" :src="backImg" alt>
      </div>
    </div> -->
    <div class="main">
      <div class="main-nav">
        <div
          v-for="(item, index) in originOrder.category"
          :key="index"
          :style="
            item.categoryId === categoryId
              ? 'background:#fff;height: 44px;'
              : 'height: 44px;'
          "
          @click="selectionFn(item.categoryId, item.categoryName)"
        >
          <div
            class="nav-title"
            :style="item.categoryId === categoryId ? 'color:#333;' : ''"
          >
            <div class="nav-name">{{ item.categoryName }}</div>
          </div>
        </div>
      </div>
      <div style="flex: 1; overflow: auto; background: #fff; height: 100%">
        <div
          style="
            height: 44px;
            line-height: 44px;
            margin-left: 20px;
            font-size: 16px;
            color: #333;
          "
        >
          {{ categoryName }}
        </div>
        <div
          v-for="item in originOrder.productList"
          :key="item.id"
          style="padding: 0 15px; box-sizing: border-box; margin: 15px 0"
        >
          <div
            v-if="item.categoryId === categoryId || categoryId === -1"
            style="
              display: flex;
              flex-direction: row;
              height: 90px;
              align-items: center;
              box-sizing: border-box;
            "
          >
            <div style="width: 90px; height: 100%">
              <img
                :src="item.logo ? `${apiURL}/image/?name=${item.logo}` : dish"
                alt
                style="width: 100%; height: 100%; border-radius: 5px"
                @click="showImg(item.logo)"
              />
            </div>
            <div
              style="
                margin-left: 12px;
                flex: 1;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                box-sizing: border-box;
              "
            >
              <div>
                <div style="color: #333; font-size: 16px; line-height: 1">
                  {{ item.name }}
                </div>
                <div
                  style="
                    font-size: 13px;
                    color: #999;
                    line-height: 1;
                    margin-top: 12px;
                  "
                >
                  <!-- <span style="margin-right: 5px">月售{{ item.stock }}</span> -->
                  <span>库存{{ item.stock }}份</span>
                </div>
                <div v-if="productLimitNum" class="limit-num">
                  限购{{ productLimitNum }}份
                </div>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                  padding-right: 2px;
                "
              >
                <div class="meal-price">
                  <div class="origin-price">
                    ￥{{
                      (
                        (item.price * $store.getters.discountConfig) /
                        100 /
                        100
                      ).toFixed(2)
                    }}
                  </div>
                  <div
                    style="
                      font-size: 10px;
                      line-height: 1;
                      color: #666666;
                      text-decoration: line-through #666666;
                      -webkit-text-decoration: line-through #666666;
                    "
                  >
                    ￥{{ (item.price / 100).toFixed(2) }}
                  </div>
                </div>
                <van-stepper
                  v-model="item.num"
                  :min="0"
                  :max="productLimitNum || item.stock"
                  :default-value="0"
                  integer
                  theme="round"
                  button-size="20"
                  :disable-input="true"
                  @change="onNumberChange(item.id, $event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="flag"
      style="
        background: #fff6e5;
        height: 29px;
        margin-bottom: 12px;
        text-align: center;
        color: #ffa900;
        font-size: 13px;
        line-height: 26px;
      "
    >
      起送金额{{ minimumAmount }}元
    </div>
    <div class="footer" style="height: 60px">
      <div class="amount-content" @click="showDetail">
        <div class="amount">
          <div
            style="
              width: 52px;
              height: 52px;
              position: relative;
              margin-right: 10px;
            "
          >
            <div
              style="
                width: 52px;
                height: 52px;
                background: #fff;
                border-radius: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                padding: 14px;
                position: absolute;
                top: -22px;
                box-shadow: 0 2px 9px 0 #99999929;
              "
            >
              <img :src="dishImg" alt style="width: 100%; height: 100%" />
            </div>
            <div
              style="
                position: absolute;
                top: -28px;
                right: -8px;
                height: 18px;
                background: #f00703;
                border-radius: 9px;
                color: #fff;
                font-size: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                padding: 0px 6px;
              "
            >
              {{ totalNumber > 999 ? '999+' : totalNumber }}
            </div>
          </div>
          <div style="flex: 1; display: flex; flex-direction: row">
            <div>合计：</div>
            <div style="color: #f00; font-size: 18px">
              ￥{{ (amount / 100).toFixed(2) }}
            </div>
            <!-- <div v-if="amount" :style="amount ? 'color: #999;margin-left: 6px;' : 'font-size: 1rem;font-weight: bold;color: #f00;'" >
              已优惠
              <span
                :style="amount ? 'color: #999;margin-left: 6px;' : 'font-size: 1rem;font-weight: bold;color: #f00;'"
              >￥{{ ((originalCost-amount) / 100).toFixed(2) }}</span>
            </div> -->
          </div>
        </div>
        <div style="width: 110px">
          <van-button
            style="
              width: 100%;
              background: #fa5050;
              color: #fff;
              font-size: 18px;
            "
            round
            @click.stop="onSubmit"
          >
            下单
          </van-button>
        </div>
      </div>
    </div>
    <van-overlay :show="loading" class="init">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import { isArray, floor } from 'lodash';
import backImg from '@/assets/images/backImg.png';
import dish from '@/assets/dish.png';
import dishImg from '@/assets/images/dishImg.png';
import clear from '@/assets/clear.png';
import { mealDetail, getVisitMealAllProductsListAPI } from '@/api/order';
import { Toast, ImagePreview } from 'vant';
// import { Toast, ImagePreview, Dialog } from 'vant'
import moment from 'moment';
import topHeader from '@/components/topHeader';
export default {
  name: 'Detail',
  components: { topHeader },
  data() {
    return {
      dish,
      dishImg,
      totalNum: 0,
      loading: false,
      id: '',
      clear,
      show: false,
      apiURL: process.env.VUE_APP_BASE_API,
      backImg,
      order: [],
      originOrder: {},
      // 订餐地址
      msg: '',
      // false 堂食    true 外送
      flag: false,
      categoryId: -1,
      isWhite: '',
      phone: '',
      user: {},
      type: '',
      categoryName: '全部',
      canteenId: '',
      productLimitNum: '',
      minimumAmount: '0'
    };
  },
  computed: {
    originalCost() {
      const list = this.originOrder.productList;
      let total = 0;
      isArray(list) &&
        list.map((item) => {
          total += item.num * item.price;
        });
      return total;
    },
    totalNumber() {
      const list = this.originOrder.productList;
      let total = 0;
      isArray(list) &&
        list.map((item) => {
          total += item.num;
        });
      return total;
    },
    amount() {
      let { discountConfig } = this.$store.getters;
      if (!discountConfig) discountConfig = 100;
      const list = this.originOrder.productList;
      let total = 0;
      isArray(list) &&
        list.map((item) => {
          total += item.num * item.price;
        });
      return floor((total * discountConfig) / 100);
    },
    num() {
      return (list) => {
        let amount = 0;
        list &&
          list.length > 0 &&
          list.map((item) => {
            if (item.num) amount += item.num;
          });
        this.totalNum = amount;
        if (amount === 0) this.show = false;
        return !!amount;
      };
    }
  },
  created() {
    this.isWhite = sessionStorage.getItem('isWhite');
    this.phone = sessionStorage.getItem('phone');
    this.organizationId = sessionStorage.getItem('organizationId');
    this.user = JSON.parse(sessionStorage.getItem('personInfo'));
    if (!this.user && !this.phone && !this.organizationId) {
      this.$router.push({ name: 'OrderLogin' });
      return;
    }
    const { id, msg, flag, type, canteenId } = JSON.parse(
      this.$route.query.order
    );
    this.msg = msg;
    this.flag = flag;
    this.type = type;
    this.canteenId = canteenId;
    if (!id) {
      this.$router.push({ name: 'Home' });
      return;
    }
    this.id = id;
    this.fetch(id);
  },
  methods: {
    onNumberChange(id, current) {
      const list = this.originOrder.productList;
      if (!isArray(list)) return;
      const index = list.findIndex((item) => item.id === id);
      if (index === -1) return;
      const item = list[index];
      if (Number(current) > Number(item.stock)) {
        Toast({
          duration: 1500,
          message: '库存不足'
        });
        this.$nextTick(() => {
          Vue.set(this.originOrder.productList, index, {
            ...item,
            num: item.stock
          });
        });
      }
    },

    // 点击菜品图片放大显示
    showImg(logo) {
      const { apiURL } = this;
      if (logo) {
        ImagePreview({
          images: [`${apiURL}/image/?name=${logo}`],
          closeable: true
        });
      } else {
        ImagePreview({
          images: [this.dish],
          closeable: true
        });
      }
    },
    async onSubmit() {
      const {
        originOrder: { productList, endTime },
        id,
        originalCost,
        amount,
        msg,
        flag,
        type,
        canteenId
      } = this;
      const now = moment().format('YYYY-MM-DD HH:mm:ss');
      const before = moment(endTime).isBefore(now, 'second');

      if (before) {
        Toast('已过订餐时间，订餐失败！');
        return;
      }

      if (!amount) return;

      const products =
        (productList &&
          productList.length > 0 &&
          productList.filter((item) => {
            if (item.num) {
              return {
                id: item.id,
                name: item.name,
                logo: item.logo,
                price: item.price,
                num: item.num
              };
            }
          })) ||
        [];
      const reduceCost = originalCost - amount;
      const order = {
        mealId: id,
        amount,
        originalCost,
        reduceCost,
        products,
        address: msg,
        mode: flag ? 'take_out_meal' : 'hall_meal',
        endTime,
        type,
        canteenId
      };
      if (
        Number(order.originalCost) / 100 < this.minimumAmount &&
        order.mode === 'take_out_meal'
      ) {
        Toast.fail('订餐金额小于起送金额，无法下单');
        return;
      }
      this.$router.push({
        name: 'OrderForm',
        query: { item: JSON.stringify(order) }
      });
    },
    // 清空购物车
    clearDish() {
      const {
        originOrder: { productList }
      } = this;
      this.originOrder.productList =
        productList &&
        productList.length > 0 &&
        productList.map((item) => {
          item.num = 0;
          return { ...item };
        });
    },
    async fetch(id) {
      if (this.phone && this.organizationId) {
        const res = await getVisitMealAllProductsListAPI(this.organizationId);
        const { code, data, message } = res;
        const newArr = [
          {
            categoryId: -1,
            categoryName: '全部'
          }
        ];
        if (code === 0) {
          data.category.map((item) => {
            newArr.push(item);
          });
          newArr.push({
            categoryId: -2,
            categoryName: '其他'
          });
          data.productList.map((item) => {
            if (!item.categoryId) {
              item.categoryId = -2;
            }
          });
          data.category = newArr;
          this.originOrder = data;
          this.loading = false;
        } else {
          console.log(message);
        }
      } else {
        const res = await mealDetail(id, {
          canteenId: this.canteenId
        });
        const { code, data, message } = res;
        this.productLimitNum = data.productLimitNum;
        const newArr = [
          {
            categoryId: -1,
            categoryName: '全部'
          }
        ];
        if (code === 0) {
          data.category.map((item) => {
            newArr.push(item);
          });
          newArr.push({
            categoryId: -2,
            categoryName: '其他'
          });
          data.productList.map((item) => {
            if (!item.categoryId) {
              item.categoryId = -2;
            }
          });
          data.category = newArr;
          this.originOrder = data;
          this.loading = false;
          if (res.data.canteenConfig) {
            const config = JSON.parse(res.data.canteenConfig);
            if (config[res.data.type].minimumAmount) {
              this.$set(
                this,
                'minimumAmount',
                Number(config[res.data.type].minimumAmount / 100).toFixed(2)
              );
            } else {
              this.$set(this, 'minimumAmount', 0.0);
            }
          } else {
            this.$set(this, 'minimumAmount', 0.0);
          }
        } else {
          console.log(message);
        }
      }
    },
    toOrder() {
      this.$router.push({ name: 'Order' });
    },
    showDetail() {
      this.show = true;
    },
    toBack() {
      this.$router.back();
    },
    selectionFn(id, categoryName) {
      this.categoryId = id;
      this.categoryName = categoryName;
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  // 去除滚动条
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-ms-scrollbar {
    width: 0;
  }

  .header {
    width: 100%;
    background: #fff;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: start;
  }

  .main {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: auto;
    box-sizing: border-box;
    border-top: 1px solid #f7f7f7;
    .main-nav {
      width: 80px;
      overflow: auto;
      background: #f7f7f7;
      height: 100%;
      font-size: 14px;
      box-sizing: border-box;
      .nav-title {
        height: 100%;
        width: 80px;
        padding: 0 15px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        color: #666;
        .nav-name {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
    .main-content {
      flex: 1;
      overflow: auto;
      background: #fff;
      height: 100%;
      .main-items {
        padding: 0 15px;
        box-sizing: border-box;
        margin-bottom: 15px;
        height: 90px;
        .items-content {
          display: flex;
          flex-direction: row;
          height: 100%;
          align-items: center;
          box-sizing: border-box;
          background: pink;

          .dish-items {
            margin-left: 10px;
            height: 70px;
            flex: 1;
            .dish-name {
              color: #333;
              font-weight: bold;
            }
            .dish-stock {
              font-size: 14px;
              color: #999;
              margin-bottom: 10px;
            }
            .dish-info {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }

  & > .footer {
    .amount-content {
      z-index: 2000;
      position: fixed;
      bottom: 0;
      box-shadow: 0 -5px 5px -5px #f2f2f2;
      background-color: #fff;
      width: 100%;
      padding: 0.625rem 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      .amount {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      // .text {
      //   color: #333;
      //   font-weight: 600;
      //   .sign {
      //     font-size: 0.625rem;
      //     color: red;
      //   }
      //   .total-num {
      //     font-size: 1.25rem;
      //     color: red;
      //     font-weight: 600;
      //     margin-left: 0.2rem;
      //   }
      //   .no-total-num {
      //     font-weight: normal;
      //     color: #333;
      //     text-decoration: line-through;
      //     margin-left: 6px;
      //   }
      // }

      // & .text-he {
      //   padding: 0.1em;
      //   color: black;
      //   border-radius: 40%;
      // }
    }
    .pop-content {
      padding: 1rem;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .choosed {
          color: #333;
          font-weight: 600;
        }
        .clear {
          display: flex;
          align-items: center;
          font-size: 0.875rem;
        }
      }
      .content {
        margin-top: 0.875rem;
        padding: 0 0 0.35rem;
        max-height: 30rem;
        box-sizing: border-box;
        overflow-x: scroll;
        .card {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;
          img {
            width: 4rem;
            height: 4rem;
            border-radius: 0.3rem;
          }
          .detail {
            margin-left: 1rem;
            width: 100%;
            .name {
              font-weight: 600;
              font-size: 1rem;
            }
            .num {
              display: flex;
              justify-content: space-between;
              margin-top: 0.5rem;
              .price {
                color: red;
                font-size: 0.75rem;
                .price-num {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
.limit-num {
  color: #ffa900;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  margin-top: 6px;
}
.meal-price {
  color: #f00;
  max-width: 82px;
  font-size: 16px;
  margin-right: 2px;
  display: flex;
  flex-direction: row;
  align-items: end;
  /* 允许换行 */
  flex-wrap: wrap;
}

.origin-price {
  line-height: 1;
}

::v-deep .van-button--round {
  border-radius: 999px;
  height: 2.55rem;
  padding: 0 1.25rem;
  font-size: 1rem;
}
::v-deep .init.van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 4000;
}
::v-deep .footer .van-popup--bottom {
  z-index: 1009 !important;
}
::v-deep .footer .van-overlay {
  z-index: 1005 !important;
}

::v-deep .van-stepper--round .van-stepper__minus {
  color: #fa5050;
  background-color: #fff;
  cursor: not-allowed;
  border-color: #fa5050;
}
::v-deep .van-stepper--round .van-stepper__plus {
  color: #fff;
  background-color: #fa5050;
  border-color: #fa5050;
}

::v-deep .van-stepper--round .van-stepper__minus--disabled {
  display: none;
  color: #ff7373;
  background-color: #fff;
  border-color: #ff7373;
  cursor: not-allowed;
}
::v-deep .van-stepper--round .van-stepper__plus--disabled {
  color: #fff;
  background-color: #ff7373;
  border-color: #ff7373;
  cursor: not-allowed;
}

::v-deep .van-stepper__input {
  width: 25px;
}
</style>
